import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const contacts = [  
    // { 
    //     id: 1, 
    //     icon: <FaPhone />,
    //     title: 'contact',
    //     content: [ '+254715179534'],
    // },
    // {
    //     id: 2,
    //     icon: <FaEnvelope />,
    //     title: 'email',
    //     content: [ ' awasirovers@gmail.com'],
    // },
    {
        id: 3,
        icon: <FaMapMarkerAlt />,
        title: 'address',
        content: ['Po Box 9-40122','Awasi, Kenya'],
    },
 
];

export default contacts;