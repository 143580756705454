import { images } from '.';

const players = [  
    {
        id: 1,
        image: images.player1,
        name: 'Dennis Obel',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    }, 
    {
        id: 2,
        image: images.player2,
        name: 'Kevin Otieno',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 3,
        image: images.player3,
        name: 'Dean Obondi',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 4,
        image: images.player4,
        name: 'Opemo Opemo',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    }, 
    {
        id: 5,
        image: images.player1,
        name: 'Godfrey Owino',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 6,
        image: images.player2,
        name: 'Ted Onyango',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 7,
        image: images.player3,
        name: 'Felix Mwangi',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 8,
        image: images.player4,
        name: 'Keith Opiyo',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 9,
        image: images.player1,
        name: 'Anderson Paak',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 10,
        image: images.player2,
        name: 'Benny Butcher',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 11,
        image: images.player3,
        name: 'Marshal Mathers',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 12,
        image: images.player4,
        name: 'Shem Ogembo',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 13,
        image: images.player1,
        name: 'Nelson Mandela',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 14,
        image: images.player2,
        name: 'Andrew Miyenda',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 15,
        image: images.player3,
        name: 'Moses Blair',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },
    {
        id: 16,
        image: images.player4,
        name: 'Joseph Ogada',
        title: 'Goalkeeper/Captain',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        personalInfo: {
            nationality: 'Kenyan',
            DOB: '30 July, 2013 (Age 10)',
            height: '6ft (183)',
            batting: 'Right-Handed',
            bowling: 'Right-Arm Off Break',
            position: 'Goalkeeper/Captain'
        },
        careerInfo: {
            clubDebut: '2022',
            curClub: 'Rovers CC',
            prevClub: 'Wolf CC (2015 - 2018)',
            matches: '25',
            runScored: '1378',
            battingAvg: '29.31',
            fullhalfcenturies: '4/6',
            topScore: '156',
            ballsbowled: '132',
            wickets: '2',
            bowlingAvg: '37.50',
            bestBowling: '1/6'
        }
    },

];

export default players;